import React from "react";
import mapBg from '../../assets/map.png'
import {FaPhoneAlt, FaMapMarkerAlt, FaFacebook} from 'react-icons/fa'
import {MdEmail} from 'react-icons/md'
import {AiOutlineTwitter, AiFillLinkedin, AiOutlineWhatsApp, AiOutlineRight, AiFillYoutube} from 'react-icons/ai'


function LpssFooter() {

    return (
        <div className="tw-relative tw-flex tw-flex-col tw-bg-dark tw-pt-24 tw-pb-2 tw-px-6 tw-overflow-hidden tw-z-10">
            <div className="tw-flex tw-w-full tw-relative">
                <div className="tw-flex tw-flex-col tw-w-full lg:tw-w-4/5 lg:tw-px-32 tw-pb-10 tw-justify-between">
                    <h3 className="tw-text-white tw-text-2xl">Signup for Newsletter</h3>
                    <div className="tw-flex tw-flex-col lg:tw-flex-row">
                        <div className="tw-flex-col mt-1">
                            <input type="email" name="email" id="email"
                                   className="tw-px-20 tw-py-3 tw-bg-white tw-shadow-sm
                                            tw-placeholder-dark tw-block tw-w-full
                                            tw-text-lg" placeholder="Email Address"/>
                        </div>
                        <div className="tw-mt-2 lg:tw-mt-1 tw-text-center">
                            <p className="tw-relative tw-border tw-border-accent hover:tw-bg-accent tw-transition-all tw-px-16 tw-text-center tw-py-2.5 lg:tw-ml-4 tw-text-white tw-text-xl">Submit</p>
                        </div>
                    </div>
                </div>
                <div
                    className="tw-flex tw-w-full lg:tw-w-1/3 tw-top-1/3  tw-left-1/2 lg:tw-left-0 -tw-translate-y-1/2 lg:-tw-translate-y-0 lg:-tw-translate-x-0 -tw-translate-x-1/2 tw-absolute tw-opacity-40 tw-px-6">
                    <img src={mapBg} className="tw-w-full tw-h-auto" alt=""/>
                </div>
            </div>
            <div className="tw-flex tw-w-full">
                <div className="w-full tw-flex tw-flex-col lg:tw-flex-row lg:tw-px-32 py-4">
                    <div className="tw-flex tw-flex-col tw-w-full lg:tw-px-3 lg:tw-w-2/6">
                        <h2 className="tw-text-white tw-text-lg lg:tw-text-xl">Yadu | Giving is Living</h2>
                        <p className="tw-text-xs md:tw-text-base lg:tw-text-xs tw-mt-1 tw-leading-snug tw-text-gray-300">At Lal Pari Swasthya
                            Sadan(LPS Sadan). We are dedicated to bridging the healthcare divide in rural communities. We
                            believe that quality healthcare is a fundamental right, and we strive to make it accessible
                            to every resident in Devhital village, Madhubani district, Bihar.</p>
                    </div>
                    <div className="tw-w-full tw-grid tw-grid-cols-2 lg:tw-grid-cols-4">
                        <div className="tw-flex tw-flex-col tw-text-white tw-w-full lg:tw-ml-3">
                            <h2 className="tw-text-white tw-text-lg lg:tw-text-2xl">Our Head Office</h2>
                            <p className="tw-text-xs md:tw-text-base tw-flex tw-items-center tw-mt-2">
                                <FaMapMarkerAlt className='tw-w-6 tw-h-6 tw-mr-2'/>
                                Debihtola, Madhubani, Bihar- 847404, India
                            </p>
                            <a id="call_btn"
                               className="tw-text-xs md:tw-text-base tw-flex tw-items-center tw-my-2"
                               href="tel:+917091804541">
                                <FaPhoneAlt className=' tw-w-4 tw-h-4 tw-mr-2'/>+91 70918 04541</a>
                            <p href="" className="tw-text-xs md:tw-text-base tw-flex tw-items-center tw-my-2">
                                <MdEmail className='tw-w-4 tw-h-4 tw-mr-2'/><span>
                                <a href="mailto:jhatara123@gmail.com">jhatara123@gmail.com</a>,<br/> <a
                                href="mailto:yadutrustorg@gmail.com">yadutrustorg@gmail.com</a>
                            </span>
                            </p>
                        </div>
                        <div className="tw-flex tw-flex-col tw-text-white lg:tw-pl-7 tw-w-full">
                            <h2 className="tw-text-white tw-text-lg lg:tw-text-2xl">Popular Links</h2>
                            <ul className="relative">
                                <a href="/#about_s/"><li className="tw-text-xs md:tw-text-base tw-flex tw-items-center tw-my-2"><AiOutlineRight />About Us</li></a>
                                <a href="/contact/"><li className='tw-text-xs md:tw-text-base tw-flex tw-items-center tw-my-2'><AiOutlineRight />Contact Us</li></a>
                                <a href="/lalpari_sewa_sadan/"><li className='tw-text-xs md:tw-text-base tw-flex tw-items-center tw-my-2'><AiOutlineRight />Popular Causes</li></a>
                            </ul>
                        </div>
                        <div className="tw-flex tw-flex-col lg:tw-pl-6 tw-text-white tw-w-full">
                            <h2 className="tw-text-white tw-text-lg lg:tw-text-2xl">Useful Links</h2>
                            <ul className="relative">
                                <a href="/termsofuse/"><li className="tw-text-xs md:tw-text-base tw-flex tw-items-center tw-my-2"><AiOutlineRight className=''/>Terms of use</li></a>
                                <a href="/privacy-policy/"><li className="tw-text-xs md:tw-text-base tw-flex tw-items-center tw-my-2"><AiOutlineRight className=''/>Privacy policy</li></a>
                                <a href="/disclaimer/"><li className="tw-text-xs md:tw-text-base tw-flex tw-items-center tw-my-2"><AiOutlineRight className=''/>Disclaimer</li></a>
                            </ul>
                        </div>
                        <div className="tw-flex tw-flex-col lg:tw-px-6 tw-text-white tw-w-full">
                            <h2 className="tw-text-white tw-text-lg lg:tw-text-2xl">Follow Us</h2>
                            <div className="tw-flex tw-flex-row tw-justify-normal lg:tw-justify-between tw-gap-2 lg:tw-gap-0 tw-flex-wrap">
                                <a href='https://www.facebook.com/yadungo' className="tw-text-xs lg:tw-text-base tw-flex tw-items-center tw-my-2"><FaFacebook className='tw-h-6 tw-w-6'/></a>
                                <a href='https://wa.me/917091804541' className="tw-text-xs lg:tw-text-base tw-flex tw-items-center tw-my-2"><AiOutlineWhatsApp className='tw-h-6 tw-w-6'/></a>
                                <a href='https://www.linkedin.com/company/yadunandan-shiksha-seva-sansthan/?originalSubdomain=in' className="tw-text-xs lg:tw-text-base tw-flex tw-items-center tw-my-2"><AiFillLinkedin className='tw-h-6 tw-w-6'/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tw-flex tw-w-full tw-justify-center tw-items-center tw-text-center">
                <div className="tw-flex tw-justify-center tw-text-center tw-py-3 tw-border-t tw-text-primary">
                    &copy; 2024 Yadu Trust. All rights reserved.
                </div>
            </div>
        </div>
    )
}

export default LpssFooter;
