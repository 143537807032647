import React from 'react';
import {Outlet} from 'react-router-dom'

import Header from './components/Component/Header';
import Footer from './components/Component/Footer_main';
import LpssFooter from "./components/Component/LpssFooter";

function App() {

    return (
        <>
            <Header/>
            <Outlet/>
            <LpssFooter/>
            {/*<Footer />*/}
        </>
    );
}

export default App;
