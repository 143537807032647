import React from 'react'
function Team() {
    return (
        <>
            {/* <!-- Team Start --> */}
            <div className="team">
                <div className="container">
                    <div className="section-header text-center">
                        {/* <p>Meet the visionary</p> */}
                        <h2>Meet the visionary</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="team-item">
                                <div className="team-img">
                                    <img src="assets/img/tarakant.jpg" alt="Team-logo"  />
                                    {/* <img src="assets/img/AboutTeam.jpg" alt="Team-logo" style={{height:'100px'}}/> */}
                                </div>
                                <div className="team-text">
                                    <h2>Sh. Tara Kant Jha </h2>
                                    <p>Founder, Visionary and Social Crusader </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="team-about">
                                <h6>Mr. Jha, the settler trustee of the Yadunandan Shiksha Seva Sansthan Trust, is a septuagenarian and a long-time social crusader. His guiding principle, “Society and people matter first,” inspired the establishment of the Trust. Under his visionary leadership, the Trust has undertaken numerous initiatives since its inception. These include setting up an English medium school for primary students, preserving traditional heritage, and establishing a robust primary healthcare system. Mr. Jha’s belief in the philosophy of “Giving is Living” continues to inspire and motivate the community.</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Team End --> */}
        </>
    )
}

export default Team